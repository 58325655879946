a {
    color: #FFFFFF;
    text-decoration: underline;
}

@media only screen and (min-width: 768px) {
    .created {
        display: inline-block;
    }
}

@media only screen and (max-width: 767px) {
    .created {
        display: block;
    }
}