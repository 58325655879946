.ui.secondary.pointing.menu {
    border: none;
}
.border {
    border-bottom-color: white;
    border-bottom-style: solid;
    border-bottom-width: thin;
}
.ui.secondary.inverted.menu .link.item,
.ui.secondary.inverted.menu a.item {
    color: rgba(255,255,255,.9) !important;
}
.ui.menu .ui.dropdown .menu>.item {
    background-color: #1b1c1d !important;
    color: rgba(255,255,255,.9) !important;
}
/* .ui.large.top.fixed.menu {
    background-color: #1b1c1d !important;
} */