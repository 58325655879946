@media only screen and (min-width: 768px) {
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .componentWrapper {
        display: inline-block;
        width: 45%;
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .wrapper {
        display: inline-block;
    }
    .componentWrapper {
        display: block;
        width: 100%;
        text-align: center;
    }
}