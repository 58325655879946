.ui.inverted.text.menu .item {
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid rgba(255,255,255,.9) !important;
}

@media only screen and (min-width: 768px) {
    .ui.inverted.text.vertical.menu a.item:hover {
        background-color: rgba(255,255,255,.9) !important;
        color: #1b1c1d !important;
    }
}

@media only screen and (max-width: 767px) {
    .ui.inverted.text.menu {
        min-width: 100%;
        max-width: 100%;
    }
}