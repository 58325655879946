img {
    max-width: 100%;
    min-width: 100%;
}

@media only screen and (min-width: 640px) {
    .player {
        display: inline-block;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 414px) {
    .player {
        display: block;
        width: 100% !important;
        height: 100% !important;
    }
}