@media only screen and (min-width: 768px) {
    .captionFont {
        font-size: 1.25em;
    }
}

@media only screen and (max-width: 767px) {
    .captionFont {
        font-size: 1em;
    }
}