.ui.form .field>label {
    color: white;
}

.ui.button {
    min-width: 100%;
    max-width: 100%;
}

.ui.input textarea,
.ui.form textarea {
    color: #000;
}